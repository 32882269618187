import './LinkAccountsForm.css'
import React from 'react'
import { Button, CardDeck } from 'react-bootstrap'
import * as Contract from '../api/contract'
import PlantynAccountStatus from './PlantynAccountStatus'
import InformatAccountStatus from './InformatAccountStatus'

interface LinkAccountsFormProps {
    plantynAccount: Contract.PlantynAccountStatusDto
    informatAccount: Contract.InformatAccountStatusDto

    doLinkAccounts: () => void
    isLoading: boolean
    error: string
}

interface LinkAccountsFormState {

}

class LinkAccountsForm extends React.Component<LinkAccountsFormProps, LinkAccountsFormState> {

    constructor(props: LinkAccountsFormProps) {
        super(props)

        this._doLinkAccounts = this._doLinkAccounts.bind(this);
    }

    _doLinkAccounts(event: React.MouseEvent<HTMLButtonElement>): void {
        this.props.doLinkAccounts()
    }

    render() {

        let alreadyLinkedToCurrentAccount = false
        let linkIconColorClass = "text-secondary"
        let btnDisabled = false
        let btnIcon = "fa fa-link"
        let linkAccountsBtn = <span />
        let apiErrorDiv = <span />
        let linkedAccountWarningDiv = <span />
        let linkedAccountInfoDiv = <span />

        if (this.props.informatAccount.plantynAccountId === this.props.plantynAccount.id) {
            linkIconColorClass = "text-success"
            alreadyLinkedToCurrentAccount = true
            linkedAccountInfoDiv = <div className="alert alert-info">Deze Informat account is reeds gekoppeld aan de huidige Informat-Scoodle account. Je kan hiermee inloggen op de Informat applicaties.</div>
        }

        if (alreadyLinkedToCurrentAccount) {


        } else {
            if (this.props.informatAccount.plantynAccountId !== '00000000-0000-0000-0000-000000000000') {
                const warningMsg = "Opgelet! Er is reeds een Informat-Scoodle account gekoppeld aan deze Informat account, indien u doorgaat wordt deze koppeling verwijderd en wordt de Informat account exclusief aan de huidige Informat-Scoodle account gekoppeld"
                linkedAccountWarningDiv = <div className="alert alert-warning">{warningMsg}</div>
            }

            if (this.props.isLoading) {
                btnDisabled = true
                btnIcon = "fa fa-sync fa-spin"
            }

            linkAccountsBtn = (
                <Button variant="success" onClick={this._doLinkAccounts} disabled={btnDisabled}>
                    <span className={btnIcon}></span> Link Accounts
                </Button>)
        }

        if (this.props.error !== "") {
            apiErrorDiv = <div className="alert alert-danger">{this.props.error}</div>
        }

        return (
            <div>
                <CardDeck>
                    <PlantynAccountStatus plantynAccount={this.props.plantynAccount} />
                    <div className={"huge-link-icon " + linkIconColorClass}>
                        <span className="fa fa-link fa-4x"></span>
                    </div>
                    <InformatAccountStatus informatAccount={this.props.informatAccount} />
                </CardDeck>

                <div className="link-warning-wrapper">
                    {linkedAccountWarningDiv}
                    {linkedAccountInfoDiv}
                </div>

                {linkAccountsBtn}

                {apiErrorDiv}
            </div>
        )
    }
}

export default LinkAccountsForm