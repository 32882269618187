import React from 'react'
import { Card } from 'react-bootstrap'
import * as Contract from '../api/contract'

interface PlantynAccountStatusProps {
    plantynAccount: Contract.PlantynAccountStatusDto
}

interface PlantynAccountStatusState {

}

class PlantynAccountStatus extends React.Component<PlantynAccountStatusProps, PlantynAccountStatusState> {

    render() {

        const imgUrl = this.props.plantynAccount.img === "" ? "img/unknown-user.png" : this.props.plantynAccount.img

        let providerUrl = "img/plantyn.png"
        let providerTooltip = ""

        switch (this.props.plantynAccount.idProvider) {
            case "Google":
                providerUrl = "img/google.png"
                providerTooltip = "provided by Google"
                break

            default:
                providerUrl = "img/plantyn.png"
                providerTooltip = "provided by Plantyn"
        }

        return (
            <div>
                <Card style={{ width: '18rem' }} className="text-center">
                    <Card.Header as="h5">Informat-Scoodle account</Card.Header>
                    <Card.Body className="account-img">
                        <img src={imgUrl} className="account-img" alt="Profiel foto" />
                    </Card.Body>
                    <Card.Body>
                        <Card.Title>{this.props.plantynAccount.name}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                            <small>{this.props.plantynAccount.email}</small>
                        </Card.Subtitle>
                    </Card.Body>
                    <Card.Footer style={{ textAlign: "right" }}>
                        <img src={providerUrl} title={providerTooltip} className="idProvider" alt={this.props.plantynAccount.idProvider} />
                    </Card.Footer>
                </Card>
            </div>
        )
    }
}

export default PlantynAccountStatus