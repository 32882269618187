import React from "react"
import { Route, Switch } from "react-router"
import { BrowserRouter, Link } from "react-router-dom"

import Authenticate, { OidcSettings } from "./components/Authenticate"
import LandingPage from "./pages/LandingPage"
import ActivationPage from "./pages/ActivationPage"
import LinkedAccountsPage from "./pages/LinkedAccountsPage"
import * as Contract from "./api/contract"
import { Navbar, Nav } from "react-bootstrap";

interface AppProps {
    oidcSettings: OidcSettings
}

interface AppState {
    user: Contract.User | undefined
}

class App extends React.Component<AppProps, AppState> {

    constructor(props: AppProps) {
        super(props)

        this._userLoaded = this._userLoaded.bind(this)
        this._userUnloaded = this._userUnloaded.bind(this)
        this._notAuthenticated = this._notAuthenticated.bind(this)

        this.state = { user: undefined }
    }

    _userLoaded(user: Contract.User) {
        if (user)
            this.setState({ "user": user });
    }

    _userUnloaded() {
        this.setState({ "user": undefined });
    }

    _notAuthenticated(signinFn: () => void): any {
        return <LandingPage signin={() => { console.log("attempting login"); signinFn(); }} />
    }



    render() {

        return (
            <div className="container">
                <Authenticate
                    oidcSettings={this.props.oidcSettings}
                    userLoaded={this._userLoaded}
                    userUnloaded={this._userUnloaded}
                    renderNotAuthenticated={this._notAuthenticated}>

                    <BrowserRouter>
                        <Navbar bg="dark" variant="dark" expand="md">
                            <Navbar.Toggle />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto">
                                    <Link className="nav-link" to="/">Activatie code gebruiken</Link>
                                    <Link className="nav-link" to="/account">Gelinkte accounts</Link>
                                </Nav>
                            </Navbar.Collapse>

                        </Navbar>

                        <Switch>
                            <Route exact={true} path="/account" render={props => (
                                this.state.user ?
                                    <LinkedAccountsPage user={this.state.user} />
                                    : <div>Loading...</div>
                            )} />

                            <Route exact={false} path="/" render={props => (
                                this.state.user ?
                                    <ActivationPage user={this.state.user} />
                                    : <div>Loading...</div>
                            )} />
                        </Switch>
                    </BrowserRouter>
                </Authenticate>
            </div>
        )
    }
}

export default App