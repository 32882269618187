import React from 'react'
import moment from 'moment'
import { Card } from 'react-bootstrap'
import * as Contract from '../api/contract'

interface InformatAccountStatusProps {
    informatAccount: Contract.InformatAccountStatusDto
}

interface InformatAccountStatusState {

}

class InformatAccountStatus extends React.Component<InformatAccountStatusProps, InformatAccountStatusState> {

    render() {
        const validUntil = moment(this.props.informatAccount.activationCodeValidUntil).format("D MMMM 'YY, H:mm")
        const imgUrl = "img/unknown-user.png"

        return (
            <div>
                <Card style={{ width: '18rem' }} className="text-center">
                    <Card.Header as="h5">Informat account</Card.Header>
                    <Card.Body className="account-img">
                        <img src={imgUrl} className="account-img" alt="Profiel foto" />
                    </Card.Body>
                    <Card.Body>
                        <Card.Title>{this.props.informatAccount.accountDescription}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                            <small>{this.props.informatAccount.accountName}</small>
                        </Card.Subtitle>
                    </Card.Body>
                    <Card.Footer className="text-right">
                        <small>Code geldig tot {validUntil}</small>
                    </Card.Footer>
                </Card>
            </div>
        )
    }
}

export default InformatAccountStatus