import React from 'react'
import { Jumbotron, CardDeck } from 'react-bootstrap'
import * as Contract from '../api/contract'
import Api from '../api/'
import ManageLinkedInformatAccount from '../components/ManageLinkedInformatAccount';

interface LinkedAccountsPageProps {
    user: Contract.User
}

interface LinkedAccountsPageState {
    plantynAccount: Contract.PlantynAccountStatusDto
    informatAccounts: Contract.InformatAccountDto[]
    isLoading: boolean
    loadingError: string
}

class LinkedAccountsPage extends React.Component<LinkedAccountsPageProps, LinkedAccountsPageState> {
    constructor(props: LinkedAccountsPageProps) {
        super(props)

        this.state = {
            plantynAccount: {
                id: this.props.user.profile.sub,
                firstname: this.props.user.profile.given_name,
                lastname: this.props.user.profile.family_name,
                name: this.props.user.profile.name,
                email: this.props.user.profile.email,
                img: this.props.user.profile.picture,
                idProvider: this.props.user.profile.idp
            },
            informatAccounts: [],
            isLoading: false,
            loadingError: ""
        }

        this.loadAccounts = this.loadAccounts.bind(this)
    }

    componentDidMount() {
        this.loadAccounts()
    }

    loadAccounts() {

        this.setState({
            ...this.state,
            isLoading: true,
            loadingError: ""
        })

        Api
            .getLinkedAccounts(this.props.user.profile.sub, this.props.user.access_token)
            .then(
                (response: Contract.InformatAccountListResponse) => {
                    if (response.success) {
                        this.setState({
                            ...this.state,
                            loadingError: "",
                            isLoading: false,
                            informatAccounts: response.data,
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            isLoading: false,
                            loadingError: response.errorMessage,
                            informatAccounts: []
                        })
                    }

                }
            )
            .catch(
                (error: Error) => {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        loadingError: error.message,
                        informatAccounts: []
                    })
                }
            )
    }

    render() {



        if (this.state.isLoading || !this.state.plantynAccount) {
            return (
                <div className="text-center text-secondary" style={{ padding: "50px 0" }}>
                    <span className="fa fa-sync fa-2x fa-spin"></span> Accounts ophalen
                </div>
            )
        }

        if (this.state.informatAccounts.length === 0 && !this.state.isLoading) {
            return (
                <div className="alert alert-warning">
                    <p>Er zijn geen <strong>Informat accounts</strong> gekoppeld aan uw <strong>Informat-Scoodle account</strong>.</p>
                    <p><a href="/">Klik hier om een account te koppelen met een activatie code</a></p>
                </div>
            )
        }

        return (
            <div className="container">
                <Jumbotron>
                    <h2><strong>Informat accounts</strong> gelinkt aan uw <strong>Informat-Scoodle account</strong></h2>
                    <p className="lead">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse mattis id sem sed finibus. Mauris sit amet risus eu erat lobortis volutpat.
                    </p>

                    <CardDeck>
                        {this.state.informatAccounts.map((account, idx) => {
                            return <ManageLinkedInformatAccount
                                key={account.gebruikerId}
                                informatAccount={account}
                                unlinkAccount={(accountId: string) => console.log('deleting link with ' + accountId)}
                            />
                        })}
                    </CardDeck>


                </Jumbotron>
            </div>
        )
    }

}

export default LinkedAccountsPage
