import * as Contract from "./contract"

async function callApi<T>(method: string, path: string, accessToken: string, data?: any): Promise<T> {
    var headers: any = {
        Accept: "application/json",
        "content-type": "application/json",
    }
    if (accessToken !== "") {
        headers["Authorization"] = `Bearer ${accessToken}`;
    }

    const response = await fetch(
        path,
        {
            method,
            headers: headers,
            body: data ? JSON.stringify(data) : null
        });


    if (response.status === 401) {
        throw new Error("Authorization failed");
    }

    const responseData = await response.json();

    // check for error format
    var responseSuccess = (responseData as Contract.ResponseBase).success;
    if (responseSuccess !== undefined && !responseSuccess) throw new Error((responseData as Contract.ResponseBase).errorMessage);

    return responseData as T;
}



export default {
    getActivationCodeStatus: async (activationCode: string, accessToken: string): Promise<Contract.InformatAccountStatusResponse> => {
        return await callApi<Contract.InformatAccountStatusResponse>("GET", '/api/activation/status/' + activationCode, accessToken);
    },
    activateUsingCode: async (activationCode: string, plantynAccountId: string, accessToken: string): Promise<Contract.InformatAccountStatusResponse> => {
        return await callApi<Contract.InformatAccountStatusResponse>("POST", '/api/activation/activate/' + plantynAccountId + '/' + activationCode, accessToken);
    },
    getLinkedAccounts: async (plantynAccountId: string, accessToken: string): Promise<Contract.InformatAccountListResponse> => {
        return await callApi<Contract.InformatAccountListResponse>("GET", "/api/hats/" + plantynAccountId, accessToken);
    },
    loadConfig: async (): Promise<Contract.ConfigResponse> => {
        return await callApi<Contract.ConfigResponse>("GET", "/config", "");
    }
}