
import { Component } from 'react';
import * as Contract from '../api/contract'
import Api from '../api/index'


/////////////////

interface ConfigLoaderProps {
    configLoaded: (config: Contract.ConfigResponse) => void
    loading: () => any
    ready: () => any
}

interface ConfigLoaderState {
    isLoaded: boolean
}

class ConfigLoader extends Component<ConfigLoaderProps, ConfigLoaderState> {
    constructor(props: ConfigLoaderProps) {
        super(props)

        this.state = { isLoaded: false }
    }

    componentDidMount() {
        // Once the configuration is loaded set `isLoaded` to true so we know to render our component
        Api.loadConfig()
            .then(config => {

                // Call parent function to store actual config
                if (config) {
                    this.props.configLoaded(config)
                }

                this.setState({
                    isLoaded: true
                })
            }
            )
    }

    render() {
        if (!this.state.isLoaded) {
            return this.props.loading ? this.props.loading() : null;
        }
        // The config is loaded so show the component set on the `ready()` props
        //return this.props.ready(this.state.config);
        return this.props.ready();
    }
}
export default ConfigLoader