import React from 'react'
import { Form, Button, InputGroup, Spinner } from 'react-bootstrap'

interface ActivationCodeFormProps {
    getActivationCode: (activationCode: string) => void
    isLoading: boolean
    error: string
}

interface ActivationCodeFormState {
    activationCode: string
}

class ActivationCodeForm extends React.Component<ActivationCodeFormProps, ActivationCodeFormState> {

    constructor(props: ActivationCodeFormProps) {
        super(props)

        this.state = {
            activationCode: ''
        }

        this._onFormValueChanged = this._onFormValueChanged.bind(this);
        this._onFormSubmit = this._onFormSubmit.bind(this);
    }

    _onFormValueChanged(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            ...this.state,
            [event.target.id]: event.target.value
        })
    }

    _onFormSubmit(event: React.MouseEvent<HTMLButtonElement>): void {
        this.props.getActivationCode(this.state.activationCode)
    }

    render() {

        let btnIcon = <span className='fa fa-user-check'></span>
        if (this.props.isLoading) {
            btnIcon = <Spinner animation="border" size="sm" variant="light" />
        }

        return (
            <div>
                <Form>
                    <Form.Row>
                        <Form.Group controlId="formGroupActivationCode">
                            <Form.Label>Vul uw activatie code in</Form.Label>
                            <InputGroup className="mb-3">
                                <input
                                    id='activationCode'
                                    type='text'
                                    placeholder='e.g S1G3D6FK'
                                    maxLength={8}
                                    className='form-control'
                                    value={this.state.activationCode}
                                    onChange={this._onFormValueChanged} />
                                <InputGroup.Append>
                                    <Button variant="info" type="button" onClick={this._onFormSubmit}>
                                        {btnIcon} Valideer
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                            <div className="text-danger">{this.props.error}</div>
                        </Form.Group>
                    </Form.Row>
                </Form>

            </div>
        )
    }
}

export default ActivationCodeForm