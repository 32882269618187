import React from 'react'
import { Jumbotron, Button } from 'react-bootstrap'

interface LandingPageProps {
    signin: () => void
}

class LandingPage extends React.Component<LandingPageProps> {
    constructor(props: LandingPageProps) {
        super(props)

        this.doSignin = this.doSignin.bind(this)
    }

    doSignin(evt: any) {
        this.props.signin()
    }

    render() {

        return (

            <div className="container">
                <Jumbotron>
                    <h2>Link uw <strong>Informat account</strong> aan uw <strong>Informat-Scoodle account</strong></h2>
                    <p className="lead">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse mattis id sem sed finibus. Mauris sit amet risus eu erat lobortis volutpat.
                        Pellentesque eu ex quis tellus dignissim imperdiet. Sed tempor dolor ex, sit amet fringilla purus elementum at.
                        Vestibulum non placerat libero. Mauris hendrerit nibh ac molestie porta. Pellentesque vel purus a sapien luctus ultricies.
                        Nam quis iaculis libero.
                    </p>
                    <p>
                        Phasellus pellentesque tristique est, a pharetra odio tempus vel. Nulla facilisi. Nam vitae elit diam.
                        Nam a scelerisque odio. Etiam iaculis tellus enim, nec hendrerit lectus efficitur nec.
                        Pellentesque in nisl ac erat scelerisque rhoncus. Nunc convallis fermentum enim in pharetra.
                        Nullam vel lacus sodales, vestibulum neque sed, interdum mauris.
                    </p>
                    <Button variant='primary' id="btn-plantyn-login" onClick={this.doSignin}>
                        <span className="fa fa-door-open"></span> Log in met je Plantyn Account
                    </Button>
                </Jumbotron>
            </div>
        )
    }

}

export default LandingPage
