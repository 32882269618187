import React from 'react'
import { Jumbotron } from 'react-bootstrap'
import Api from '../api/'
import * as Contract from '../api/contract'
import ActivationCodeForm from '../components/ActivationCodeForm'
import LinkAccountsForm from '../components/LinkAccountsForm'
import { Redirect } from 'react-router-dom';

interface ActivationPageProps {
  user: Contract.User
}

interface ActivationPageState {
  activationCode: string
  isLoading: boolean
  loadingError: string
  informatAccount: Contract.InformatAccountStatusDto | null
  linkingSuccessful: boolean
}

class ActivationPage extends React.Component<ActivationPageProps, ActivationPageState> {

  constructor(props: ActivationPageProps) {
    super(props)

    this.state = {
      activationCode: "",
      isLoading: false,
      loadingError: "",
      informatAccount: null,
      linkingSuccessful: false
    }

    this._enterActivationCode = this._enterActivationCode.bind(this)
    this._linkAccounts = this._linkAccounts.bind(this)
  }

  _enterActivationCode(activationCode: string) {
    this.setState({
      ...this.state,
      activationCode: activationCode,
      isLoading: true,
      loadingError: ''
    })

    Api
      .getActivationCodeStatus(activationCode, this.props.user.access_token)
      .then(
        (response: Contract.InformatAccountStatusResponse) => {
          if (response.success) {
            this.setState({
              ...this.state,
              loadingError: "",
              isLoading: false,
              informatAccount: response.data
            })
          } else {
            this.setState({
              ...this.state,
              isLoading: false,
              loadingError: response.errorMessage,
              informatAccount: null
            })
          }

        }
      )
      .catch(
        (error: Error) => {
          this.setState({
            ...this.state,
            isLoading: false,
            loadingError: error.message,
            informatAccount: null
          })
        }
      )
  }

  _linkAccounts() {
    if (!this.props.user) return

    this.setState({
      ...this.state,
      isLoading: true,
      loadingError: ''
    })

    Api
      .activateUsingCode(this.state.activationCode, this.props.user.profile.sub, this.props.user.access_token)
      .then(
        (response: Contract.InformatAccountStatusResponse) => {
          if (response.success) {
            this.setState({
              ...this.state,
              loadingError: "",
              isLoading: false,
              informatAccount: response.data,
              activationCode: "",
              linkingSuccessful: true
            })
          } else {
            this.setState({
              ...this.state,
              loadingError: response.errorMessage,
              isLoading: false,
              informatAccount: null
            })
          }

        }
      )
      .catch(
        (error: Error) => {
          this.setState({
            ...this.state,
            isLoading: false,
            loadingError: error.message,
            informatAccount: null
          })
        }
      )
  }

  render() {

    if (this.state.linkingSuccessful) {
      return <Redirect to="/account" />
    }


    const plantynAccount: Contract.PlantynAccountStatusDto = {
      id: this.props.user.profile.sub,
      firstname: this.props.user.profile.given_name,
      lastname: this.props.user.profile.family_name,
      name: this.props.user.profile.name,
      email: this.props.user.profile.email,
      img: this.props.user.profile.picture,
      idProvider: this.props.user.profile.idp
    }

    const content =
      this.state.informatAccount == null ?
        <ActivationCodeForm
          getActivationCode={this._enterActivationCode}
          isLoading={this.state.isLoading}
          error={this.state.loadingError}
        />
        :
        <LinkAccountsForm
          informatAccount={this.state.informatAccount}
          plantynAccount={plantynAccount}
          doLinkAccounts={this._linkAccounts}
          isLoading={this.state.isLoading}
          error={this.state.loadingError}
        />

    return (

      <div className="container">
        <Jumbotron>
          <h2>Link uw <strong>Informat account</strong> aan uw <strong>Informat-Scoodle account</strong></h2>
          <p className="lead">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Suspendisse mattis id sem sed finibus. Mauris sit amet risus eu erat lobortis volutpat.
            Pellentesque eu ex quis tellus dignissim imperdiet. Sed tempor dolor ex, sit amet fringilla purus elementum at.
            Vestibulum non placerat libero.
          </p>
          <p>
            Phasellus pellentesque tristique est, a pharetra odio tempus vel. Nulla facilisi. Nam vitae elit diam.
            Nam a scelerisque odio. Etiam iaculis tellus enim, nec hendrerit lectus efficitur nec.
            Pellentesque in nisl ac erat scelerisque rhoncus.
          </p>

          {content}

        </Jumbotron>
      </div>
    )
  }

}

export default ActivationPage
