import './index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import ConfigLoader from './components/ConfigLoader'
import Loading from './components/Loading'

import * as Contract from './api/contract'
import { OidcSettings } from './components/Authenticate';

let oidcSettings: OidcSettings = {
    authority: "",
    client_id: "",
    scope: "",
    response_type: "",
    redirect_uri: "",
    silent_redirect_uri: "",
    post_logout_redirect_uri: "",
    automaticSilentRenew: true,
    checkSessionInterval: 60000,
    accessTokenExpiringNotificationTime: 60
}

function _loadConfig(config: Contract.ConfigResponse) {
    oidcSettings = {
        authority: config.authority,
        client_id: config.clientId,
        scope: config.scope,
        response_type: config.responseType,
        redirect_uri: config.redirectUri + '/signin-oidc',
        silent_redirect_uri: config.redirectUri + '/silent-refresh.html',
        post_logout_redirect_uri: config.postLogoutRedirectUri,
        extraQueryParams: {
            'clientReturnUrl': config.redirectUri + '/#logged_in'
        },
        automaticSilentRenew: false,
        checkSessionInterval: 60000,
        accessTokenExpiringNotificationTime: 60
    }
}

ReactDOM.render((
    <ConfigLoader
        ready={() => <App oidcSettings={oidcSettings} />}
        loading={() => <Loading />}
        configLoaded={(config: Contract.ConfigResponse) => _loadConfig(config)} />
), document.getElementById('root'));