import React from 'react'
import { Card } from 'react-bootstrap'
import * as Contract from '../api/contract'

interface ManageLinkedInformatAccountProps {
    informatAccount: Contract.InformatAccountDto
    unlinkAccount: (accountId: string) => void
}

interface ManageLinkedInformatAccountState {

}

class ManageLinkedInformatAccount extends React.Component<ManageLinkedInformatAccountProps, ManageLinkedInformatAccountState> {
    constructor(props: ManageLinkedInformatAccountProps) {
        super(props)

        this.doUnlinkAccount = this.doUnlinkAccount.bind(this)
    }


    doUnlinkAccount() {
        this.props.unlinkAccount(this.props.informatAccount.accountId)
    }

    render() {
        const imgUrl = "img/unknown-user.png"

        let inactiveAccount = <span className="text-success"><span className="fa fa-check"></span> Account actief</span>

        if (!this.props.informatAccount.isActive) {
            inactiveAccount = <span className="text-danger"><span className="fa fa-times"></span> Account niet actief</span>
        }

        return (
            <div>
                <Card style={{ width: '18rem' }} className="text-center">
                    <Card.Body className="account-img">
                        <img src={imgUrl} className="account-img" alt="Profiel foto" />
                    </Card.Body>
                    <Card.Body>
                        <Card.Title>{this.props.informatAccount.accountDescription}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                            <small>
                                {this.props.informatAccount.accountName}
                            </small>
                        </Card.Subtitle>
                    </Card.Body>
                    <Card.Footer>
                        {inactiveAccount}
                        {/* <Button variant='danger' onClick={this.doUnlinkAccount}>
                            <span className='fa fa-unlink'></span> Verwijder
                        </Button> */}
                    </Card.Footer>
                </Card>
            </div>
        )
    }
}

export default ManageLinkedInformatAccount