import React from "react"
import Oidc, { UserManager } from "oidc-client"
import * as Contract from "../api/contract"

export interface OidcSettings {
    /**
     * @property {string} OidcSettings.authority The URL of the OIDC/OAuth2 provider.
     */
    authority: string
    /**
     * @property {string} OidcSettings.client_id  Your client application's identifier as registered with the OIDC/OAuth2 provider.
     */
    client_id: string
    /**
     * @property {string} OidcSettings.redirect_uri The redirect URI of your client application to receive a response from the OIDC/OAuth2 provider.
     */
    redirect_uri: string
    silent_redirect_uri: string
    /**
     * @property {string} OidcSettings.post_logout_redirect_uri The URL of the OIDC/OAuth2 provider.
     */
    post_logout_redirect_uri: string
    /**
     * @property {string} OidcSettings.response_type The type of response desired from the OIDC/OAuth2 provider ( default: 'id_token').
     */
    response_type: string
    /**
     * @property {string} OidcSettings.scope The scope being requested from the OIDC/OAuth2 provider (default: 'openid').
     */
    scope: string
    /**
     * @property {boolean} OidcSettings.automaticSilentRenew Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration. The attempt is made as a result of the accessTokenExpiring event being raised.
     */
    automaticSilentRenew: boolean
    /**
     * @property {number} OidcSettings.accessTokenExpiringNotificationTime The number of seconds before an access token is to expire to raise the accessTokenExpiring event.
     */
    accessTokenExpiringNotificationTime: number
    /**
     * @property {number} OidcSettings.checkSessionInterval Interval, in ms, to check the user's session
     */
    checkSessionInterval: number

    extraQueryParams?: {};
}

interface Props {
    oidcSettings: OidcSettings
    /**
     * @property {func} userLoaded Raised when a user session has been established (or re-established), accepts one parameter 'user'.
     */
    userLoaded: (user: any) => void
    /**
     * @property {func} userUnLoaded Raised when a user session has been terminated.
     */
    userUnloaded: () => void
    /**
     * @property {func} renderNotAuthenticated Renderprop used to render output when user is not authenticated
     */
    renderNotAuthenticated: (signinFn: () => void) => any
}

interface InternalState {
    isAuthenticated: boolean
}

/** 
 * @render react
 * @name Authenticate
 * @description OpenId Connect based Authentication Component
 * @example  
 * <Authenticate OidcSettings={this.OidcSettings} userLoaded={this.userLoaded} userunLoaded={this.userUnLoaded} renderNotAuthenticated={this.NotAuthenticated}>
      <div>If you see this you are authenticated.</div>
   </Authenticate>
 */
class Authenticate extends React.Component<Props, InternalState> {
    _userManager: UserManager

    constructor(props: any) {
        super(props)

        Oidc.Log.logger = console;

        this.signin = this.signin.bind(this)
        this.onUserLoaded = this.onUserLoaded.bind(this)

        this._userManager = new UserManager(this.props.oidcSettings)
        this._userManager.startSilentRenew()

        this.state = {
            isAuthenticated: false
        }
    }

    componentDidMount() {
        // Wiring up events from OIDC library to component methods
        this._userManager.events.addUserLoaded(this.onUserLoaded)
        this._userManager.events.addUserUnloaded(this.onUserUnloaded)

        this._userManager.events.addSilentRenewError(function (err) { console.debug('silent renewal failed'); console.error(err); })
        this._userManager.events.addAccessTokenExpired(function () { console.debug('token expired'); })
        this._userManager.events.addAccessTokenExpiring(function () { console.debug('token expiring'); })

        // Try to get the user from the OIDC client
        this._userManager.getUser().then((user) => {

            // Check if a user was found
            if (user !== null && user !== undefined && !user.expired) {
                // Load the user
                this.onUserLoaded(user)

            }

            // No (valid) user found, checking if this is the callback for user-interactive signin
            else if (window.location.href.includes("/signin-oidc#id_token")) {
                console.debug('signin_oidc#id_token')
                this._userManager.signinRedirectCallback().then(() => {
                    window.history.replaceState({}, "", "/")
                }).catch(function (err) {
                    console.log("Error signinRedirectCallback: ", err)
                })
            }

            // No (valid) user found, checking if this is the callback after signin was successful
            else if (window.location.href.includes("#logged_in")) {
                console.debug('logged in!')
                this.signin() // trigger signin again, because the user has logged in on Identity Server

            }

            // No (valid) user found, interactive signin required
            else {
                console.debug('User needs to click the login button')
            }
        })
    }

    onUserLoaded(user: Contract.User) {
        console.debug('Authenticate.onUserLoaded')
        this.setState({
            isAuthenticated: true
        })

        if (this.props.userLoaded !== undefined)
            this.props.userLoaded(user)
    }

    onUserUnloaded() {
        this.setState({
            isAuthenticated: false
        })

        if (this.props.userUnloaded !== undefined)
            this.props.userUnloaded()
    }

    signin() {
        // Add extra query params like Client
        this._userManager.signinRedirect().then(function () {
            console.debug('signinRedirect ok')
        }).catch(function (err) {
            console.debug('signinRedirect error:', err)
        })
    }

    render() {
        if (this.state.isAuthenticated) {
            return (this.props.children)
        } else {
            return <div>{this.props.renderNotAuthenticated(this.signin)}</div>
        }

    }
}

export default Authenticate